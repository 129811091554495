import { useAuth } from "context/AuthContext";
import { BiLogOut } from "react-icons/bi";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { actionLog } from "services/actionlog.service";
import "./styles.scss";

interface MenuProps {
  onClose?: () => void;
}
export function MobileMenu(props: MenuProps) {
  const { signOut } = useAuth();
  function handleLinkClass({ isActive }: any) {
    const baseClass = isActive
      ? "bg-blue-dark text-white text-center py-2 md:px-4 md:py-2 font-normal ring-1 ring-blue-dark shadow-lg active"
      : "bg-white text-[#0D4AC5] border-[#CCCCCC] text-center py-2 md:px-4 md:py-2 font-normal ring-1 ring-[#CCCCCC]";
    return `${baseClass} hover:text-white`;
  }

  function handleActionLog(data: any) {
    actionLog("interaction", data);
  }

  return (
    <div
      id="menu"
      className="fixed p-4 w-[217px] min-h-[311px] top-2 right-0 mobile-nav space-y-3"
    >
      <div className="w-[full] flex justify-end">
        <button
          onClick={() => {
            if (props.onClose) props.onClose();
          }}
          className="text-black w-[30px] h-[30px] rounded-full flex items-center justify-center"
        >
          <MdClose size={35} />
        </button>
      </div>

      <div className="flex flex-col space-y-2 w-[full]">
        <NavLink
          to="/inicio"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "inicio",
              itemTitle: "INÍCIO",
              type: "page_view",
            })
          }
        >
          INÍCIO
        </NavLink>
        <NavLink
          to="/objetivos"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "eixos",
              itemTitle: "EIXOS",
              type: "page_view_custom",
            })
          }
        >
          EIXOS
        </NavLink>
        <NavLink
          to="/desafios"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "desafios",
              itemTitle: "DESAFIOS",
              type: "page_view",
            })
          }
        >
          DESAFIOS
        </NavLink>
        {/* <NavLink
          to="/reta-final"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "retaFinal",
              itemTitle: "RETA FINAL",
              type: "page_view_custom",
            })
          }
        >
          RETA FINAL
        </NavLink> */}
        <NavLink
          to="/quadro-medalhas"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "quadroMedalhas",
              itemTitle: "QUADRO DE MEDALHAS",
              type: "page_view_custom",
            })
          }
        >
          QUADRO DE MEDALHAS
        </NavLink>
        <NavLink
          to="/recordistas"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "recordistas",
              itemTitle: "RECORDISTAS",
              type: "page_view_custom",
            })
          }
        >
          RECORDISTAS
        </NavLink>
        <NavLink
          to="/premios"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "meusCards",
              itemTitle: "MEUS CARDS",
              type: "page_view",
            })
          }
        >
          CARDS 2023
        </NavLink>
        <NavLink
          to="/campanha"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "aCampanha",
              itemTitle: "A CAMPANHA",
              type: "page_view_custom",
            })
          }
        >
          A CAMPANHA
        </NavLink>
        <NavLink
          to="/como-ganhar"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "formasDeGanhar",
              itemTitle: "FORMAS DE GANHAR",
              type: "page_view_custom",
            })
          }
        >
          FORMAS DE GANHAR
        </NavLink>
        <NavLink
          to="/experiencias"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "mercadoDeEstrelas",
              itemTitle: "MERCADO DE ESTRELAS",
              type: "page_view",
            })
          }
        >
          MERCADO DE ESTRELAS
        </NavLink>
        <NavLink
          to="/aniversario"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "aniversarioBrb58",
              itemTitle: "ANIVERSÁRIO 58 ANOS",
              type: "page_view",
            })
          }
        >
          ANIVERSÁRIO 58 ANOS
        </NavLink>
        {/* <NavLink
          to="/convencao"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "convencao",
              itemTitle: "CONVENÇÃO",
              type: "page_view_custom",
            })
          }
        >
          CONVENÇÃO
        </NavLink> */}
        <NavLink
          to="/extrato"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "extrato",
              itemTitle: "EXTRATO",
              type: "page_view",
            })
          }
        >
          EXTRATO
        </NavLink>
        {/* <NavLink
          to="/resgates"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "resgates",
              itemTitle: "RESGATES",
              type: "page_view",
            })
          }
        >
          RESGATES
        </NavLink> */}
        <NavLink
          to="/contato"
          className={handleLinkClass}
          onClick={() =>
            handleActionLog({
              itemId: "ajuda",
              itemTitle: "AJUDA",
              type: "page_view",
            })
          }
        >
          AJUDA
        </NavLink>

        <div className="pt-10 w-full">
          <button
            type="button"
            onClick={() => signOut()}
            className={handleLinkClass({ isActive: false }) + " w-full"}
          >
            <div className="flex items-center justify-center space-x-2">
              <div>
                <BiLogOut
                  className="transform rotate-180 text-[#0D4AC5]"
                  size={20}
                />
              </div>
              <div>SAIR</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
